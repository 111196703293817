import styled from 'styled-components'

export const SAMLAuthProviderStyledContainer = styled.div`
  display: grid;
  grid-template-areas: 'EP' 'CP' 'IF' 'IC';
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: var(--spacing-2);

  .form-input-container[class*='form-input-container--jsonConfig.entryPoint'] {
    grid-area: EP;
  }

  .form-input-container[class*='form-input-container--jsonConfig.certPath'] {
    grid-area: CP;
  }

  .form-input-container[class*='form-input-container--jsonConfig.identifierFormat'] {
    grid-area: IF;
  }

  .form-input-container[class*='form-input-container--jsonConfig.icon'] {
    grid-area: IC;
  }
`
