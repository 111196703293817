import React from 'react'
import { Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import { ExtensionFormBase } from 'methone/components/ExtensionFormBase'
import { HookFormInput, HookFormSwitch } from 'methone/components/ui/Forms'
import { i18n } from 'methone/services/i18n'
import { EXTENSION_STATUS } from 'methone/shared/constants'
import { ExtensionResponseDTO } from 'methone/shared/dto/ExtensionResponseDTO'

import { SAMLAuthProviderStyledContainer } from './styled'

const defaultValues: ExtensionResponseDTO<SAMLConfig> = {
  id: '',
  name: '',
  type: '',
  enabled: true,
  status: EXTENSION_STATUS.STOPPED,
  jsonConfig: {
    audience: false,
    certPath: '',
    entryPoint: '',
    icon: 'far fa-shield',
    identifierFormat: 'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress',
    allowUserCreation: false
  },
  createdAt: undefined,
  createdBy: '',
  updatedAt: undefined,
  updatedBy: '',
  deletedAt: undefined,
  deletedBy: ''
}

export const SAMLAuthProviderFrom: React.FC = () => {
  const { control, ...rest } = useForm<ExtensionResponseDTO<SAMLConfig>>({ defaultValues, mode: 'all' })
  const id = rest.watch('id')

  return (
    <ExtensionFormBase control={control} {...rest}>
      <Card>
        <Card.Body>
          <SAMLAuthProviderStyledContainer>
            <HookFormInput control={control} name="jsonConfig.entryPoint" label={i18n('Entry point')} />
            <HookFormInput control={control} name="jsonConfig.certPath" label={i18n('Certificate path')} />
            <HookFormInput control={control} name="jsonConfig.identifierFormat" label={i18n('Identifier Format')} />
            <HookFormInput control={control} name="jsonConfig.icon" label={i18n('Icon')} />
            <HookFormSwitch control={control} name="jsonConfig.allowUserCreation" label={i18n('Allow user creation')} />
          </SAMLAuthProviderStyledContainer>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          {id ? (
            <>Callback endpoint: {<code>/open/plugin-saml/{id}/callback</code>}</>
          ) : (
            <>Save the extension to see the callback endpoint</>
          )}
        </Card.Body>
      </Card>
    </ExtensionFormBase>
  )
}
